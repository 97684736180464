import React from "react"
import { Layout, Theme } from "@life-without-barriers/react-components"
import { ContactUsForm } from "@life-without-barriers/shared-contact-form"

const phoneNumber = "1800 935 483"

const defaultForm = <ContactUsForm formId="contact-us-fixed-cta" />

interface ContactUsLayoutProps {
  children: React.ReactNode
  fixedCTAContact?: React.ReactElement
}

const ContactUsLayout = ({
  children,
  fixedCTAContact
}: ContactUsLayoutProps) => (
  <Layout
    theme={Theme.corporateThemeFull}
    fixedCTAContact={fixedCTAContact ?? defaultForm}
    fixedCTAPhoneNumber={phoneNumber}
  >
    {children}
  </Layout>
)

export default ContactUsLayout
