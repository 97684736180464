import qs from "query-string"
import { navigate, withPrefix } from "gatsby"
import { LocationSearchParameters } from "@life-without-barriers/react-components"

declare global {
  interface Window {
    // A flag set by Gatsby when the service worker is updated so
    // Link knows to refresh the page on next navigation attempt
    ___swUpdated?: boolean
  }
}

export default (locationPageParameters: LocationSearchParameters) => {
  const url = `/contact-us/locations/?${qs.stringify(locationPageParameters)}`
  // We need to check if the service worker has been updated
  // because if it has, a gatsby navigate will trigger a page load
  // without the query string. To preserve the query string perform the
  // navigation manually.
  if (window && window.___swUpdated) {
    window.location.assign(withPrefix(url))
  } else {
    void navigate(url)
  }
}
