import {
  CTALink,
  Helmet,
  IconAt,
  IconClock,
  IconLocation,
  IconPhone,
  Interfaces,
  Link,
  LocationSearchInput,
  PreStyledComponents,
  ServiceCards,
  ServiceCardsDefaults,
  SocialMediaLinks,
  Variables,
  media
} from "@life-without-barriers/react-components"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React, { useState } from "react"
import { Site, getGatsbyImage } from "@life-without-barriers/gatsby-common"
import styled, { ThemeProps, withTheme } from "styled-components"

import { ContactUsForm } from "@life-without-barriers/shared-contact-form"
import Layout from "../../components/contact-us/Layout"
import PlacesApiLoader from "../../components/PlacesApiLoader"
import { graphql } from "gatsby"
import { helpers } from "@life-without-barriers/utilities"
import hexToRgba from "hex-to-rgba"
import selectLocation from "../../selectLocation"

const { Container, IconWrapper } = PreStyledComponents
const {
  disabilityBlueLight,
  youthPinkLight,
  agedCareTealLight,
  corporateGreenLight
} = Variables

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    corporateImage: IGatsbyImageData
    agedCareImage: IGatsbyImageData
    mentalHealthImage: IGatsbyImageData
    disabilityImage: IGatsbyImageData
    fosterCareImage: IGatsbyImageData
  }
  theme: Interfaces.Theme
}

const callToActionLinks = [
  {
    title: "Pay for a service",
    url: "https://www.payway.com.au/MakePayment?BillerCode=132894"
  },
  {
    title: "Make a donation",
    url: "/contact-us/make-a-donation/"
  },
  {
    title: "Leave a gift in your will",
    url: "/contact-us/leave-a-gift-in-your-will/"
  },
  {
    title: "Request a record or information",
    url: "https://lwb.i-sight.com/external/rr/new"
  }
]

const MainContactCard = withTheme(({ theme }: ThemeProps<Interfaces.Theme>) => (
  <div className="br2 lwb-shadow bg-white overflow-hidden w-two-thirds-l">
    <div className="bg-lwb-theme-medium pt2 pb1" />
    <div className="pb4">
      <div className="pb0 pb0-ns pa3 pa4-ns">
        <h2 className="ts-display-4 pt2 fw8 color-lwb-theme-medium pb4">
          How can we help you?
        </h2>
        <div className="pl1 pt3-ns flex flex-column flex-row-ns">
          <div className="w-40-l">
            <div className="flex items-center">
              <IconWrapper size="30" color={theme.xxxlight}>
                <IconPhone height="20" width="20" color={theme.dark} />
              </IconWrapper>
              <div className="pl3">
                <Link
                  className="black fw3 no-underline-ns f3-ns fw8-ns"
                  to="tel:1800935483"
                  ariaLabel="Phone 1 8 0 0. 9 3 5. 4 8 3"
                >
                  1800 935 483
                </Link>
              </div>
            </div>
            <div className="mt2 flex items-center">
              <IconWrapper size="30" color={theme.xxxlight}>
                <IconAt height="20" width="20" color={theme.dark} />
              </IconWrapper>
              <div className="pl3">
                <Link className="black fw4" to="mailto:info@lwb.org.au">
                  info@lwb.org.au
                </Link>
              </div>
            </div>
          </div>
          <div className="flex pv4 pv0-ns pl5-ns">
            <IconWrapper size="30" color={theme.xxxlight}>
              <IconLocation height="20" width="20" color={theme.dark} />
            </IconWrapper>
            <div className="pl3">
              <p className="mt0 fw8 mb1">Address</p>
              <p className="mt0">Level 5, 352 Hunter Street, Newcastle</p>
              <p className="mt0">NSW 2300</p>
              <CTALink
                to="https://goo.gl/maps/BrEURey7BPdDWNkA9"
                className="pt2 pt0-ns"
                text="Get directions"
                target="_blank"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pt0 pt0-ns pa3 pa4-ns">
        <div className="flex pl1">
          <IconWrapper size="30" color={theme.xxxlight}>
            <IconClock height="20" width="20" color={theme.dark} />
          </IconWrapper>
          <div className="pl3">
            <p className="mt0 fw8">Opening hours (AEST)</p>
            <p className="mt1">
              <SpacedSpan>Mon - Fri</SpacedSpan>9AM - 4:30PM
            </p>
            <p className="mt0">
              <SpacedSpan>Sat - Sun</SpacedSpan>Closed
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
))

interface BackgroundBannerProps {
  children: React.ReactChild
  corporateImage: IGatsbyImageData
  serviceImage: IGatsbyImageData
  serviceColor: string
  agedCareImage: IGatsbyImageData
  disabilityImage: IGatsbyImageData
  fosterCareImage: IGatsbyImageData
  otherServicesImage: IGatsbyImageData
  selectedService: string
}

const BackgroundBanner = ({
  children,
  corporateImage,
  serviceColor,
  agedCareImage,
  disabilityImage,
  fosterCareImage,
  otherServicesImage,
  selectedService
}: BackgroundBannerProps) => (
  <BannerWrapper color={serviceColor} className="relative z-0">
    <div className="z-2 relative">{children}</div>
    <div className="dn db-ns">
      <StyledImageWrapper
        className={`${
          selectedService === "agedcare" ? "fadeIn" : ""
        } absolute absolute--fill z-1 overflow-hidden`}
      >
        <BannerImage
          title={`Banner Image`}
          image={agedCareImage}
          alt=""
          color={serviceColor || corporateGreenLight}
          className="aqua"
        />
      </StyledImageWrapper>

      <StyledImageWrapper
        className={`${
          selectedService === "otherservices" ? "fadeIn" : ""
        } absolute absolute--fill z-1 overflow-hidden`}
      >
        <BannerImage
          title={`Banner Image`}
          image={corporateImage}
          alt=""
          color={serviceColor || corporateGreenLight}
          className="green"
        />
      </StyledImageWrapper>

      <StyledImageWrapper
        className={`${
          selectedService === "disability" ? "fadeIn" : ""
        } absolute absolute--fill z-1 overflow-hidden`}
      >
        <BannerImage
          title={`Banner Image`}
          image={disabilityImage}
          alt=""
          color={serviceColor || corporateGreenLight}
          className="blue"
        />
      </StyledImageWrapper>

      <StyledImageWrapper
        className={`${
          selectedService === "fostercare" ? "fadeIn" : ""
        }  absolute absolute--fill z-1 overflow-hidden`}
      >
        <BannerImage
          title={`Banner Image`}
          image={fosterCareImage}
          alt=""
          color={serviceColor || corporateGreenLight}
          className="pink"
        />
      </StyledImageWrapper>

      <div className={`z-0 absolute absolute--fill overflow-hidden`}>
        <BannerImage
          title={`Banner Image`}
          image={otherServicesImage}
          alt=""
          color={serviceColor || corporateGreenLight}
          className="green"
        />
      </div>
    </div>
  </BannerWrapper>
)

interface ColorProps {
  color: string
}

const BannerWrapper = styled.div`
  background-color: ${(props: ColorProps) => props.color};
`

const BannerImage = styled(GatsbyImage)`
  display: none;
  right: -55%;
  transform: translateX(-45%);
  height: 100%;
  bottom: 0;

  :before,
  :after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 5;
  }

  &.green:before {
    background: ${corporateGreenLight};
  }
  &.blue:before {
    background: ${disabilityBlueLight};
  }
  &.pink:before {
    background: ${youthPinkLight};
  }
  &.aqua:before {
    background: ${agedCareTealLight};
  }

  :before {
    clip-path: polygon(0 0, 100% 0, 92% 100%, 0% 100%);
    width: 48%;
    opacity: 0.92;

    @media screen and (min-width: 80em) {
      width: 30%;
    }
  }

  &.green:after {
    background: linear-gradient(
      90deg,
      ${corporateGreenLight} 40%,
      ${hexToRgba(corporateGreenLight, 0)} 100%
    );
    border-left: 1px solid ${corporateGreenLight};
  }
  &.blue:after {
    background: linear-gradient(
      90deg,
      ${disabilityBlueLight} 40%,
      ${hexToRgba(disabilityBlueLight, 0)} 100%
    );
    border-left: 1px solid ${disabilityBlueLight};
  }
  &.pink:after {
    background: linear-gradient(
      90deg,
      ${youthPinkLight} 40%,
      ${hexToRgba(youthPinkLight, 0)} 100%
    );
    border-left: 1px solid ${youthPinkLight};
  }
  &.aqua:after {
    background: linear-gradient(
      90deg,
      ${agedCareTealLight} 40%,
      ${hexToRgba(agedCareTealLight, 0)} 100%
    );
    border-left: 1px solid ${agedCareTealLight};
  }

  :after {
    left: -2%;
    width: 45%;

    @media screen and (min-width: 80em) {
      width: 10%;
    }
  }

  ${media.large`
    width: 70%;
    display: block;
    right: -65%;
  `}

  img {
    left: 1px !important; /* hack to remove transparent line on medium resolutions */
    max-width: none !important;
    object-position: top center !important;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img {
      width: auto !important;
    }
  }

  @media screen and (min-width: 80em) {
    right: -70%;
  }

  @media screen and (min-width: 100em) {
    width: 55%;
    right: -70%;
  }
`

const StyledImageWrapper = styled.div`
  opacity: 0;

  &.fadeIn {
    opacity: 5;
  }
`

const ServicesContainer = styled(Container)`
  ${media.medium`
    padding-bottom: 19em;
  `}

  ${media.large`
    padding-bottom: 10em;
  `}
`

const LocationContainer = styled(Container)`
  ${media.notSmall`
    top: -7.5em;
    height: 4em;
  `}
`

const StyledLocationDiv = styled.div`
  ${media.notSmall`
    height: 15em;
  `}
`

const IndexPage = ({
  location,
  data: {
    site: { siteMetadata },
    corporateImage,
    agedCareImage,
    disabilityImage,
    fosterCareImage
  }
}: Props) => {
  const [serviceImage, setServiceImage] = useState(corporateImage)
  const [serviceColor, setServiceColor] = useState(corporateGreenLight)
  const [selectedService, setSelectedService] = useState("corporate")

  const {
    agedCareDefaults,
    disabilityDefaults,
    fosterCareDefaults,
    otherServicesDefaults
  } = ServiceCardsDefaults

  const disability = {
    ...disabilityDefaults,
    onSelectService: (service: string) => {
      setSelectedService(service)
      setServiceImage(disabilityImage)
      setServiceColor(disabilityBlueLight)
    }
  }

  const agedCare = {
    ...agedCareDefaults,
    onSelectService: (service: string) => {
      setSelectedService(service)
      setServiceImage(agedCareImage)
      setServiceColor(agedCareTealLight)
    }
  }

  const fosterCare = {
    ...fosterCareDefaults,
    onSelectService: (service: string) => {
      setSelectedService(service)
      setServiceImage(fosterCareImage)
      setServiceColor(youthPinkLight)
    }
  }

  const otherServices = {
    ...otherServicesDefaults,
    onSelectService: (service: string) => {
      setSelectedService(service)
      setServiceImage(corporateImage)
      setServiceColor(corporateGreenLight)
    }
  }

  return (
    <div>
      <Helmet
        title={`Contact us | ${siteMetadata.title}`}
        description="Contact Life Without Barriers for assistance with disability services, foster care, aged care and other programs."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={corporateImage}
      />
      <Layout>
        <div className="ph3 ph4-ns bg-lwb-grey-xxx-light pb5">
          <Container className="pv4 pv5-ns relative">
            <StyledHeading className="fw8 tc tl-ns">Contact us</StyledHeading>
          </Container>
          <Container className="flex-l">
            <MainContactCard />
            <div className="w-third-l pt4 pl3 pl5-l flex flex-column">
              <div className="pb4 pt2">
                <h2 className="ts-display-4 fw8">Media enquiries</h2>
                <CTALink
                  to={"https://www.lwb.org.au/about-us/media/"}
                  className={"pt2 pt0-ns"}
                  text={"Read more"}
                />
              </div>
              <div className="pb4 pt2">
                <h2 className="ts-display-4 fw8">Careers</h2>
                <CTALink
                  to={"https://careers.lwb.org.au/"}
                  className="pt2 pt0-ns"
                  text={"Search our jobs"}
                />
                <CTALink
                  to={"https://www.lwb.org.au/careers/"}
                  className="pt2 pt0-ns"
                  text={"Visit our careers page"}
                />
              </div>
              <div className="pb4 pt2">
                <h2 className="ts-display-4 fw8">Feedback and Complaints</h2>
                <CTALink
                  to={"/contact-us/complaints-or-feedback/"}
                  className="pt2 pt0-ns"
                  text={"Provide feedback about our services"}
                />
              </div>
            </div>
          </Container>
        </div>
        <BackgroundBanner
          corporateImage={getGatsbyImage(corporateImage)}
          serviceImage={getGatsbyImage(serviceImage)}
          serviceColor={serviceColor}
          agedCareImage={getGatsbyImage(agedCareImage)}
          otherServicesImage={getGatsbyImage(corporateImage)}
          disabilityImage={getGatsbyImage(disabilityImage)}
          fosterCareImage={getGatsbyImage(fosterCareImage)}
          selectedService={selectedService}
        >
          <div className="ph3 ph4-ns">
            <ServicesContainer className="pv4 pv5-ns">
              <h2 className="mb4 ts-display-3 tc tl-ns fw8 color-lwb-white">
                More information about our services
              </h2>
              <ServiceCards
                services={[disability, agedCare, fosterCare, otherServices]}
              />
            </ServicesContainer>
          </div>
        </BackgroundBanner>
        <div className="w-100-ns pb5-ns ph4-ns mt4">
          <LocationContainer className="relative-ns">
            <StyledLocationDiv className="center tc bg-lwb-grey-xxx-light lwb-shadow br2-ns pv4-ns ph3 pb4">
              <h2 className="fw8 pt3-ns pt4 ts-display-3">
                Find a location near you
              </h2>
              <PlacesApiLoader>
                <LocationSearchInput
                  className="pt3 pt4-ns pb2"
                  onSelect={selectLocation}
                  enabled={true}
                  onSeachButtonClick={helpers.noop}
                  onChange={helpers.noop}
                  showSearchButtonMobile={false}
                  onSeachInputFocus={helpers.noop}
                />
              </PlacesApiLoader>
            </StyledLocationDiv>
          </LocationContainer>
        </div>
        <div className="ph3 ph4-ns mt3 pv4 pv5-ns">
          <Container className="flex-l">
            <div className="w-100 w-two-thirds-l pr6-l">
              <h2 className="ts-display-4 fw8">How else can we help?</h2>
              <div className="mt3 copy">
                <p className="pb2">
                  If you have hearing or speech impairment{" "}
                  <Link
                    className="link color-lwb-black underline"
                    to="https://www.communications.gov.au/what-we-do/phone/services-people-disability/accesshub"
                    title="Government relay service"
                  >
                    National Relay Service
                  </Link>{" "}
                  can help you with your call to Life Without Barriers.
                </p>
                <div className="pv2">
                  <div>
                    <span className="fw8 pr1">Voice Relay</span>
                    <Link
                      className="black fw3 link"
                      to="tel:1300555727"
                      ariaLabel="Phone number 1 3 0 0. 5 5 5. 7 2 7"
                    >
                      1300 555 727
                    </Link>
                  </div>
                  <div>
                    <span className="fw8 pr1">TTY</span>
                    <Link
                      className="black fw3 link"
                      to="tel:133677"
                      ariaLabel="Phone number 1 3. 3 6. 7 7"
                    >
                      133677
                    </Link>
                  </div>
                  <div>
                    <span className="fw8 pr1">SMS relay number</span>
                    <Link
                      className="black fw3 link"
                      to="tel:0423677767"
                      ariaLabel="Phone number 0 4 2 3. 6 7 7. 7 6 7"
                    >
                      0423 677 767
                    </Link>
                  </div>
                </div>
                <div className="pv3 pt4-l">
                  {callToActionLinks.map((link, i) => (
                    <CTALink
                      key={i}
                      to={link.url}
                      className={"pv2 pv0-ns"}
                      text={link.title}
                    />
                  ))}
                </div>
              </div>
              <div className="mt4 flex flex-wrap items-stretch">
                <SocialMediaLinks />
              </div>
            </div>
            <div className="w-100 w-34-l mt4 mt0-l">
              <ContactUsForm />
            </div>
          </Container>
        </div>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    corporateImage: file(relativePath: { regex: "/corporate-theme.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1800)
      }
    }
    agedCareImage: file(relativePath: { regex: "/aged-care-theme.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1800)
      }
    }
    disabilityImage: file(relativePath: { regex: "/disability-theme.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1800)
      }
    }
    fosterCareImage: file(relativePath: { regex: "/foster-care-theme.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1800)
      }
    }
    mentalHealthImage: file(
      relativePath: { regex: "/mental-health-theme.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1800)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

const SpacedSpan = styled.span`
  display: inline-block;
  min-width: 100px;
`
const StyledHeading = styled.h1`
  font-size: 26px;
  line-height: 2rem;

  ${media.large`
    font-size: 36px;
    line-height: 3rem;
  `}
`

export default IndexPage
